var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var R5,csa,S5,dsa,esa,fsa,gsa,W5,X5,Z5,$5,c6,d6,hsa,U5,isa;$CLJS.M5=function(a,b){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.hZ);b=$CLJS.U0(a,b);return 0<b?b-1:null};$CLJS.N5=function(a,b,c,d){d=$CLJS.gn.l($CLJS.G([new $CLJS.k(null,4,[$CLJS.s1,$CLJS.c3(),$CLJS.q1,!0,$CLJS.v1,!0,$CLJS.n1,!0],null),d]));return $CLJS.D1.D(a,b,c,d)};$CLJS.O5=function(a,b,c){return $CLJS.N5(a,b,c,null)};
$CLJS.P5=function(a){var b=$CLJS.CH(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "field":return $CLJS.lQ.g($CLJS.EH(a));case "metadata/column":return $CLJS.W3.g(a);case "mbql/join":return $CLJS.vP.g(a);default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.Q5=function(a){return $CLJS.i5.g(a)};R5=function(a,b){return $CLJS.F.h($CLJS.z4.g(a),$CLJS.li(b,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WK,$CLJS.CI,$CLJS.TK],null)))};
csa=function(a,b){b=$CLJS.Y0(a,b);return $CLJS.m(b)?$CLJS.r5(a,b):null};S5=function(a){var b=new $CLJS.k(null,3,[$CLJS.IG,$CLJS.p.g($CLJS.nL()),$CLJS.DG,$CLJS.DG.g(a),$CLJS.lk,$CLJS.Om.h($CLJS.lk,$CLJS.DG)(a)],null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.AG,b,$CLJS.V.g(a)],null)};dsa=function(a){return $CLJS.R3(a,new $CLJS.k(null,1,[$CLJS.WK,$CLJS.Oi],null))};
esa=function(a,b,c){var d=$CLJS.gn.l;b=$CLJS.Gb($CLJS.M5(a,b))?function(){var f=$CLJS.xZ.g($CLJS.C($CLJS.hZ.g(a)));return $CLJS.m(f)?(f=csa(a,f),$CLJS.m(f)?$CLJS.vW(function(g){return $CLJS.F.h($CLJS.Gk.g(g),c)},f):null):null}():null;try{var e=$CLJS.s0(a,c)}catch(f){e=null}return d.call($CLJS.gn,$CLJS.G([b,e]))};
fsa=function(a,b){var c=$CLJS.vW(function(d){return $CLJS.F.h($CLJS.A0.g(d),a)},b);if($CLJS.m(c))return c;c=$CLJS.vW(function(d){return $CLJS.F.h($CLJS.V.g(d),a)},b);if($CLJS.m(c))return c;c=$CLJS.YF($CLJS.YD);$CLJS.m($CLJS.eG("metabase.lib.field",c))&&(b=$CLJS.UH("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([a])),$CLJS.aj.l($CLJS.G([$CLJS.Qm.h($CLJS.A0,b)]))])),b instanceof Error?$CLJS.fG("metabase.lib.field",c,$CLJS.VB(),b):$CLJS.fG("metabase.lib.field",
c,$CLJS.VB.l($CLJS.G([b])),null));return null};
gsa=function(a,b,c){var d=function(){var f=$CLJS.M5(a,b);return $CLJS.m(f)?$CLJS.V0(a,f):$CLJS.V0(a,b)}(),e=function(){var f=$CLJS.T5.g(d);if($CLJS.m(f))return f;f=$CLJS.Lu(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oZ,$CLJS.d0],null));if($CLJS.m(f))return f;f=$CLJS.m($CLJS.xZ.g(d))?$CLJS.O5(a,b,d):null;if($CLJS.m(f))return f;f=$CLJS.YF($CLJS.YD);if($CLJS.m($CLJS.eG("metabase.lib.field",f))){var g=$CLJS.UH("Cannot resolve column {0}: stage has no metadata",$CLJS.G([$CLJS.aj.l($CLJS.G([c]))]));return g instanceof
Error?$CLJS.fG("metabase.lib.field",f,$CLJS.VB(),g):$CLJS.fG("metabase.lib.field",f,$CLJS.VB.l($CLJS.G([g])),null)}return null}();return $CLJS.A(e)?fsa(c,e):null};
W5=function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.lQ);var f=$CLJS.J(c,2,null);c=$CLJS.gn.l($CLJS.G([function(){var g=$CLJS.DG.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.DG,g],null):null}(),function(){var g=$CLJS.Om.h($CLJS.lk,$CLJS.DG)(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.lk,g],null):null}(),function(){var g=$CLJS.OR.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[U5,g],null):null}(),function(){var g=$CLJS.PQ.g(e);return $CLJS.m(g)?new $CLJS.k(null,
1,[$CLJS.V5,g],null):null}(),$CLJS.Ae(f)?esa(a,b,f):$CLJS.m(d)?new $CLJS.k(null,2,[$CLJS.sl,$CLJS.hW,$CLJS.V,f],null):function(){var g=gsa(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.sl,$CLJS.hW,$CLJS.V,f],null)}()]));return $CLJS.m(d)?$CLJS.X3(c,d):c};X5=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.V5);return $CLJS.m($CLJS.m(b)?$CLJS.Ie($CLJS.VV,b):b)?$CLJS.il:$CLJS.Om.h($CLJS.lk,$CLJS.DG)(a)};
$CLJS.Y5=function(a,b){return"string"===typeof b?$CLJS.Y0(a,$CLJS.f0(b)):$CLJS.Ae(b)?$CLJS.X0(a,b):null};Z5=function(a,b,c){return $CLJS.m($CLJS.Xf(function(d){return $CLJS.F.h($CLJS.Wj.g(d),c)},a))?$CLJS.Qm.h(function(d){var e=$CLJS.Em.h(d,b);return $CLJS.F.h($CLJS.Wj.g(d),c)?$CLJS.U.j(e,b,!0):e},a):a};
$5=function(a){var b=function(){var d=$CLJS.g0.g(a),e=new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.C0,null,$CLJS.E0,null,$CLJS.u0,null],null),null);return e.g?e.g(d):e.call(null,d)}(),c=$CLJS.gn.l($CLJS.G([new $CLJS.k(null,3,[$CLJS.IG,$CLJS.p.g($CLJS.nL()),$CLJS.DG,$CLJS.DG.g(a),$CLJS.lk,X5(a)],null),function(){var d=$CLJS.P5(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.lQ,d],null):null}(),function(){var d=$CLJS.V5.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.PQ,d],null):null}(),function(){var d=
U5.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.OR,d],null):null}(),function(){var d=$CLJS.iR.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.VO,d],null):null}()]));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,c,$CLJS.m(b)?function(){var d=$CLJS.A0.g(a);return $CLJS.m(d)?d:$CLJS.V.g(a)}():function(){var d=$CLJS.Gk.g(a);return $CLJS.m(d)?d:$CLJS.V.g(a)}()],null)};
$CLJS.a6=function a6(a){switch(arguments.length){case 2:return a6.h(arguments[0],arguments[1]);case 3:return a6.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.a6.h=function(a,b){return $CLJS.a6.j(a,-1,b)};$CLJS.a6.j=function(a,b,c){return $CLJS.I1.j(a,b,c)};$CLJS.a6.v=3;
$CLJS.b6=function b6(a){switch(arguments.length){case 2:return b6.h(arguments[0],arguments[1]);case 3:return b6.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.b6.h=function(a,b){return $CLJS.b6.j(a,-1,b)};$CLJS.b6.j=function(a,b,c){return $CLJS.j5.j(a,b,c)};$CLJS.b6.v=3;$CLJS.V5=new $CLJS.N("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);
c6=new $CLJS.N(null,"parent-id","parent-id",-1400729131);$CLJS.T5=new $CLJS.N("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);d6=new $CLJS.N("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);hsa=new $CLJS.N(null,"earliest","earliest",-1928154382);U5=new $CLJS.N("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);isa=new $CLJS.N(null,"latest","latest",24323665);$CLJS.S3.o(null,$CLJS.DQ,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Oi.g(b),$CLJS.R3(c,new $CLJS.k(null,2,[$CLJS.PQ,$CLJS.Oi,$CLJS.OR,dsa],null)),a],null)});var e6=function e6(a,b){var d=$CLJS.s0(a,c6.g(b));a=$CLJS.m(c6.g(d))?e6.h?e6.h(a,d):e6.call(null,a,d):d;a=$CLJS.Qf(a);var e=$CLJS.M.h(a,$CLJS.V);return $CLJS.Rm.j(b,$CLJS.V,function(f){return[$CLJS.p.g(e),".",$CLJS.p.g(f)].join("")})};
$CLJS.A1.o(null,$CLJS.hW,function(a,b,c){return X5(c)});$CLJS.A1.o(null,$CLJS.DQ,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.PQ);$CLJS.J(c,2,null);c=W5(a,b,c);c=$CLJS.m(d)?$CLJS.U.j(c,$CLJS.V5,d):c;return $CLJS.B1.j(a,b,c)});$CLJS.Z0.o(null,$CLJS.hW,function(a,b,c){a=$CLJS.Qf(c);b=$CLJS.M.h(a,$CLJS.V);return $CLJS.U.j(a,$CLJS.V,b)});
$CLJS.Z0.o(null,$CLJS.DQ,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d),f=$CLJS.M.h(e,$CLJS.IG),g=$CLJS.M.h(e,$CLJS.DG),l=$CLJS.M.h(e,$CLJS.OR),n=$CLJS.M.h(e,$CLJS.lk);d=$CLJS.M.h(e,$CLJS.lQ);var q=$CLJS.M.h(e,$CLJS.VO),u=$CLJS.M.h(e,$CLJS.PQ),v=W5(a,b,c),x=$CLJS.gn.l;f=new $CLJS.k(null,2,[$CLJS.sl,$CLJS.hW,$CLJS.E1,f],null);e=$CLJS.TI.g(e);b=$CLJS.m(e)?e:$CLJS.a1.j(a,b,c);b=x.call($CLJS.gn,$CLJS.G([f,v,new $CLJS.k(null,1,[$CLJS.TI,b],null),$CLJS.m(n)?new $CLJS.k(null,1,[$CLJS.lk,
n],null):null,$CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.DG,g],null):null,$CLJS.m(u)?new $CLJS.k(null,1,[$CLJS.V5,u],null):null,$CLJS.m(l)?new $CLJS.k(null,1,[U5,l],null):null,$CLJS.m(q)?new $CLJS.k(null,1,[$CLJS.iR,q],null):null]));d=$CLJS.m(d)?$CLJS.X3(b,d):b;return $CLJS.m(c6.g(d))?e6(a,d):d});
$CLJS.w1.o(null,$CLJS.hW,function(a,b,c,d){c=$CLJS.Qf(c);var e=$CLJS.M.h(c,$CLJS.TI),f=$CLJS.M.h(c,$CLJS.V),g=$CLJS.M.h(c,$CLJS.Wj),l=$CLJS.M.h(c,U5),n=$CLJS.M.h(c,$CLJS.H0),q=$CLJS.M.h(c,$CLJS.iR),u=$CLJS.M.h(c,$CLJS.k0);e=$CLJS.m(e)?e:$CLJS.n5.h($CLJS.m5,f);$CLJS.F.h(d,$CLJS.b1)&&-1==e.indexOf(" → ")?($CLJS.m(q)?(q=$CLJS.s0(a,q),$CLJS.m(q)?a=(0,$CLJS.Ca)($CLJS.Pu($CLJS.TI.g($CLJS.d1.j(a,b,q)),$CLJS.n0,"")):(u=$CLJS.Y5(a,u),a=$CLJS.a1.D(a,b,u,d))):a=null,n=$CLJS.m(a)?a:$CLJS.m(n)?n:$CLJS.P5(c)):
n=null;n=$CLJS.m(n)?[$CLJS.p.g(n)," → ",$CLJS.p.g(e)].join(""):e;return $CLJS.m(g)?(c=$CLJS.mG($CLJS.Pu($CLJS.ui(g),"-"," ")),$CLJS.xa.j?$CLJS.xa.j("%s: %s",n,c):$CLJS.xa.call(null,"%s: %s",n,c)):$CLJS.m(l)?(c=$CLJS.f5(l,c),$CLJS.xa.j?$CLJS.xa.j("%s: %s",n,c):$CLJS.xa.call(null,"%s: %s",n,c)):n});
$CLJS.w1.o(null,$CLJS.DQ,function(a,b,c,d){$CLJS.J(c,0,null);var e=$CLJS.J(c,1,null),f=$CLJS.Qf(e);e=$CLJS.M.h(f,$CLJS.OR);var g=$CLJS.M.h(f,$CLJS.lQ),l=$CLJS.M.h(f,$CLJS.PQ);f=$CLJS.M.h(f,$CLJS.VO);$CLJS.J(c,2,null);c=W5(a,b,c);c=$CLJS.m(g)?$CLJS.U.j(c,$CLJS.H0,g):c;l=$CLJS.m(l)?$CLJS.U.j(c,$CLJS.Wj,l):c;e=$CLJS.m(e)?$CLJS.U.j(l,U5,e):l;e=$CLJS.m(f)?$CLJS.U.j(e,$CLJS.iR,f):e;return $CLJS.m(e)?$CLJS.a1.D(a,b,e,d):$CLJS.VH("[Unknown Field]")});
$CLJS.x1.o(null,$CLJS.hW,function(a,b,c){a=$CLJS.Qf(c);return $CLJS.M.h(a,$CLJS.V)});$CLJS.x1.o(null,$CLJS.DQ,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=W5(a,b,c);return $CLJS.m(c)?$CLJS.y1.j(a,b,c):"unknown_field"});
$CLJS.C1.o(null,$CLJS.hW,function(a,b,c){return $CLJS.gn.l($CLJS.G([function(){var d=$CLJS.tj($CLJS.C1,$CLJS.Pj);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.F.h($CLJS.g0.g(c),$CLJS.C0)?function(){var d=$CLJS.q5.g(c);return $CLJS.m(d)?(d=$CLJS.Y0(a,d),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.l0,new $CLJS.k(null,2,[$CLJS.V,$CLJS.V.g(d),$CLJS.TI,$CLJS.V.g(d)],null)],null):null):null}():null]))});$CLJS.H1.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);$CLJS.J(a,2,null);return $CLJS.PQ.g(b)});
$CLJS.H1.o(null,$CLJS.hW,function(a){return $CLJS.V5.g(a)});
$CLJS.G1.o(null,$CLJS.DQ,function(a,b){$CLJS.J(a,0,null);var c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(b)){var d=$CLJS.Ie($CLJS.VV,b),e=$CLJS.Om.j(d6,$CLJS.lk,$CLJS.DG)(c);c=$CLJS.U.l(c,$CLJS.PQ,b,$CLJS.G([$CLJS.lk,d?$CLJS.il:e,d6,e]));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,c,a],null)}b=d6.g(c);c=$CLJS.m(b)?$CLJS.Em.h($CLJS.U.j(c,$CLJS.lk,b),d6):c;c=$CLJS.Em.h(c,$CLJS.PQ);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,c,a],null)});
$CLJS.G1.o(null,$CLJS.hW,function(a,b){return $CLJS.m(b)?$CLJS.U.j(a,$CLJS.V5,b):$CLJS.Em.h(a,$CLJS.V5)});$CLJS.I1.o(null,$CLJS.DQ,function(a,b,c){return $CLJS.a6.j(a,b,W5(a,b,c))});
$CLJS.I1.o(null,$CLJS.hW,function(a,b,c){if($CLJS.ki.h($CLJS.g0.g(c),$CLJS.x0)){a=$CLJS.Om.h($CLJS.lk,$CLJS.DG)(c);b=null==c?null:$CLJS.dP.g(c);if(null==b)var d=null;else try{var e=$CLJS.SJ.g($CLJS.Wk.g(b));if($CLJS.m(e)){var f=$CLJS.Qf(e),g=$CLJS.M.h(f,hsa),l=$CLJS.M.h(f,isa),n=$CLJS.bsa.l($CLJS.G([$CLJS.L5.g(g),$CLJS.L5.g(l)]));d=isNaN(n)?null:$CLJS.m($CLJS.ot.h?$CLJS.ot.h(1,n):$CLJS.ot.call(null,1,n))?$CLJS.vz:$CLJS.m($CLJS.ot.h?$CLJS.ot.h(31,n):$CLJS.ot.call(null,31,n))?$CLJS.xz:$CLJS.m($CLJS.ot.h?
$CLJS.ot.h(365,n):$CLJS.ot.call(null,365,n))?$CLJS.Yk:$CLJS.jl}else d=null}catch(q){if(q instanceof Error)d=null;else throw q;}e=$CLJS.OH(a,$CLJS.SJ)?$CLJS.hpa:$CLJS.OH(a,$CLJS.OI)?$CLJS.gpa:$CLJS.OH(a,$CLJS.XK)?$CLJS.fpa:$CLJS.Lg;d=$CLJS.m(d)?Z5(e,$CLJS.Pj,d):e;return $CLJS.m($CLJS.V5.g(c))?Z5(d,$CLJS.i1,$CLJS.V5.g(c)):d}return $CLJS.Lg});
$CLJS.i5.o(null,$CLJS.DQ,function(a){var b=null==a?null:$CLJS.EH(a);b=null==b?null:$CLJS.OR.g(b);return null==b?null:$CLJS.U.l(b,$CLJS.sl,$CLJS.g5,$CLJS.G([$CLJS.v0,function(c,d){return W5(c,d,a)}]))});$CLJS.i5.o(null,$CLJS.hW,function(a){var b=null==a?null:U5.g(a);return null==b?null:$CLJS.U.l(b,$CLJS.sl,$CLJS.g5,$CLJS.G([$CLJS.v0,$CLJS.ag(a)]))});$CLJS.h5.o(null,$CLJS.DQ,function(a,b){return $CLJS.dW(a,$CLJS.cW,$CLJS.G([$CLJS.OR,b]))});
$CLJS.h5.o(null,$CLJS.hW,function(a,b){return $CLJS.cW(a,U5,b)});$CLJS.j5.o(null,$CLJS.DQ,function(a,b,c){return $CLJS.b6.j(a,b,W5(a,b,c))});
$CLJS.j5.o(null,$CLJS.hW,function(a,b,c){b=$CLJS.Qf(c);c=$CLJS.M.h(b,$CLJS.lk);var d=$CLJS.M.h(b,$CLJS.dP),e=$CLJS.M.h(b,$CLJS.Ll);if($CLJS.ki.h($CLJS.g0.g(b),$CLJS.x0)){var f=function(){var n=null==a?null:$CLJS.T3($CLJS.r0(a));n=null==n?null:$CLJS.xF.g(n);return null==n?null:$CLJS.Ie(n,$CLJS.OR)}(),g=$CLJS.Lu(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk,$CLJS.Fl],null)),l=$CLJS.Q5(b);return function u(q){return new $CLJS.yf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),
y=$CLJS.E(x),B=$CLJS.Bf(y);return function(){for(var I=0;;)if(I<y){var Q=$CLJS.be(x,I),Y=B,aa=Q;Q=R5(Q,l)?$CLJS.U.j(aa,$CLJS.i1,!0):aa;Y.add(Q);I+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}var H=$CLJS.C(v);return $CLJS.nf(function(){var I=H;return R5(H,l)?$CLJS.U.j(I,$CLJS.i1,!0):I}(),u($CLJS.zd(v)))}return null}},null,null)}($CLJS.Gb(function(){if($CLJS.m(f)){var q=$CLJS.qk.g(g);return $CLJS.m(q)?$CLJS.Zl.g(g):q}return f}())?null:$CLJS.OH(e,$CLJS.ml)?$CLJS.r($CLJS.wra):
$CLJS.OH(c,$CLJS.Fl)&&!$CLJS.OH(e,$CLJS.Qj)?$CLJS.r($CLJS.vra):null)}return $CLJS.Lg});$CLJS.bW.o(null,$CLJS.DQ,function(a){return a});
$CLJS.bW.o(null,$CLJS.hW,function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.g0);switch(b instanceof $CLJS.N?b.S:null){case "source/aggregations":return b=new $CLJS.k(null,2,[$CLJS.IG,$CLJS.p.g($CLJS.nL()),$CLJS.lk,$CLJS.Om.h($CLJS.lk,$CLJS.DG)(a)],null),a=$CLJS.E1.g(a),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MP,b,a],null);case "source/expressions":return S5(a);case "source/breakouts":return $CLJS.Ie(a,$CLJS.LL)?S5(a):$5(a);default:return $5(a)}});